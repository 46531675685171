<template>
	<div class="textList">
		<div class="lis">
			<a v-if="msg.url" :href="msg.url" :title="msg.title" target="_blank">
				<p><em>• </em>{{ msg.title }}</p>
				<time>{{ msg.timeline.substring(0, 10) }}</time>
			</a>
			<router-link v-else :to="'/details/cont/' + msg.id" :title="msg.title">
				<p><em>• </em>{{ msg.title }}</p>
				<time>{{ msg.timeline.substring(0, 10) }}</time>
			</router-link>
		</div>
	</div>
</template>
<script>
var _hmt = _hmt || [];
(function () {
	var hm = document.createElement('script');
	hm.src = 'https://hm.baidu.com/hm.js?819d608e55a54ab96615bb5d06bb6230';
	var s = document.getElementsByTagName('script')[0];
	s.parentNode.insertBefore(hm, s);
})();
</script>
<script>
export default {
  
  name: 'textList',
  props: {
  	'msg': {
  		type: Object,
  		default () {
  			return [];
  		}
  	},
	'id': {
		type: String,
		default: ""
	},
	'sx': {
		type: String,
		default: ""
	},
	'index': {
		type: String,
		default: ""
	},
  	'types': {
  		type: String,
  		default: ""
  	},
  	'tzfs': {
  		type: String,
  		default: "cont"
  	}
  },
  created () {
    // console.log(this.msg, 12)
  }
}
</script>

<style>
.fff {
	position: absolute;
}
</style>
